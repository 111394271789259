import { Dialog, Transition } from "@headlessui/react"
import { Fragment, MouseEventHandler } from "react"
import { useTranslation } from "react-i18next"
import { noop } from "../cross-cutting/Noop"
import { Syncronizer } from "../domain/Syncronizer"
import { useService } from "../utils/ServiceUtils"
import { Button } from "./Button"

export interface FatalErrorDialogProperties {
	isOpen: boolean
}

export const FatalErrorDialog = ({ isOpen }: FatalErrorDialogProperties) => {
	const { t } = useTranslation()
	const syncronizer = useService(Syncronizer)

	const onButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
		syncronizer.clear()
		window?.location.reload()
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				onClose={noop}
			>
				<div className="min-h-screen px-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-white opacity-50" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="inline-block h-screen align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
							<Dialog.Title
								as="h3"
								className="text-lg font-medium leading-6 text-gray-900"
							>
								{t("error.fatal.title")}
							</Dialog.Title>
							<div className="mt-2">
								<p className="text-sm text-gray-500">
									{t("error.fatal.description")}
								</p>
							</div>

							<div className="mt-4">
								<Button
									className="inline-flex justify-center px-4 py-2 text-sm font-medium text-primary-900 bg-primary-100 border border-transparent rounded-md hover:bg-primary-200 focus:outline-none ring-2 ring-offset-2 ring-primary-500"
									onClick={onButtonClick}
								>
									{t("error.fatal.button")}
								</Button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
}
